import styles from "./lendingPage.module.css"
import FooterImage from "../../components/iconsAndImages/footerImage/FooterImage";
import {Link} from "react-router-dom";
import VK from "../../img/VK.svg";
import telegramIcon from "../../img/telegram.svg";
import FSI from "../../img/FSI.svg";
import welcomeImg from "../../img/wog-welcome-img.svg";

const LendingPage = () => {

    return (
           

        <div className={styles.container}>
            
            {/* <div className={styles.navBar}><NavBar hideLogo={true}/> </div> */}
            <img className={styles.WelcomeImage} src={welcomeImg} alt={"Welcome Image"} />

            <div className={styles.content}>
                
                <b><p className={styles.headlineText}>ДОБРО ПОЖАЛОВАТЬ В ВОРКОГРАД!</p></b>
            
                <p>"Воркоград" - это платформа, объединяющая молодых специалистов и тех, кто в них нуждается. <br />
                    На этом сайте публикуют вакансии компании, готовые работать с теми, кто молод, активен, полон инициатив и знаний.<br /></p>
                
                <p>Все вакансии проходят верификацию с целью отбирать только те, которые подходят для молодых специалистов без опыта или с
                        ограниченным опытом работы по специальности. Более того, компетенции молодых специалистов оцениваются как с теоретической, так
                        и с практической точки зрения, поэтому таланты точно будут замечены!<br /></p>
                <p>Присоединяйтесь!</p>

                <div className={styles.buttonContent}>
                    <Link to={"/vacancy-search"}>
                        <button className={styles.button}>К поиску вакансий</button>
                    </Link>
                    <div class={styles.centerIcons}>
                        <p><a href=" https://t.me/Workograd" target="_blank">
                            <img className={styles.icon} src={telegramIcon} alt={"https://t.me/Workograd"} />
                        </a></p>
                        <p><a href=" https://vk.com/workograd" target="_blank">
                            <img className={styles.icon} src={VK} alt={" https://vk.com/workograd"} />
                        </a></p> 
                    </div>
                    <Link to={"/resume-search"}>
                        <button className={styles.button}>К поиску резюме</button>
                    </Link>
                </div>
                <div className={styles.buttonRowCenter}>
                    <div class={styles.centerIcons}>
                        <Link to={"/registration"}>
                            <button className={styles.button}>Зарегистрироваться</button>
                        </Link> 
                    </div>
                </div>
                <div className={styles.postText}>
                    <b><p>© 2024 ООО «Воркоград»</p></b>
                    <p>Проект реализуется при поддержке Фонда содействия развитию малых форм предприятий в научно-технической сфере (ФСИ)</p>
                    <p><a href=" https://fasie.ru/" target="_blank">
                        <img className={styles.iconFSI} src={FSI} alt={" https://fasie.ru/"} />
                    </a></p>
                </div>
			</div>
            <FooterImage />
        </div>
    )
} 
export default LendingPage;